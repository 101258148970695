.main-container1{
   
    min-height: 100vh;
    min-width: 100%;
    /* background-color: #777777; */
    background-color: white;
    overflow-y: hidden; 
    overflow-x: hidden; 
  }
  
  .product{
    padding-top: 100px;
  }

  .product .row { 
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    /* margin-left: 70px; */
    /* padding-top: -250px; */
    /* margin: auto; */
  }
  
  /* Create four equal columns that sits next to each other */
  .product .column {
     
    flex: 33%;
    max-width: 33%;
    padding: 0 10px;
    position: relative;
    top: -40px;
  }
  
  .product .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .product .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
   @media screen and (max-width: 600px) {
    .product .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  
  .product .column img{
  cursor: pointer;
  }
  
  .product .column #zoom-out figure img{
  transform: scale(.9);
  transition: 0.3s ease-in-out; 
  }
  .product .column  #zoom-out figure:hover img{
  transform: scale(1);
  background-color: aquamarine;
  }
  