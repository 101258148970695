.logo-color2 {
  color: #ead410 !important;
}

.bottommargin_40 {
  margin-bottom: 40px;
}

.margin_auto {
  margin: auto;
}

.with_border {
  border: 2px solid #e7eaeb;
}

.pointer {
  cursor: pointer;
}

.bold-text {
  font-weight: 500;
}

.post:hover .blog-img{
  -webkit-filter: blur(1.5px);
  filter: blur(1.5px);
  transition: linear 0.1s;
}

.single-post {
  margin-top: 60px;
  margin-bottom: 60px;
}

.rounded {
  border-radius: 5px;
}

.entry-title {
  margin-bottom: 1rem;
  color: var(--cloud-burst);
  font-family: var(--font-family-circular_std-bold);
  margin-top: 2.5rem;
}

.entry-title2 {
  margin-top: 1rem;
}

.entry-title a {
  color: #011932;
  text-decoration: none;
}

.entry-title a:hover {
  opacity: 0.7;
}

.bullet-points {
  font-size: 17px;
  margin-bottom: 0.8rem;
}

.content {
  color: var(--santas-gray);
  font-family: var(--font-family-roboto);
  font-weight: 300;
  line-height: 1.75rem;
}

.content-box {
  padding: 35px;
}

.link {
  text-decoration: none;
}

.link:hover {
  color: #ead410;
  opacity: 0.7;
  text-decoration: none;
}

.item-content {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .item-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .single-post {
    margin-left: 12%;
    margin-right: 12%;
  }
}