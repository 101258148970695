@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,500|Raleway:700,600|Bebas+Neue:400|Poppins:600);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,500|Raleway:700,600|Bebas+Neue:400|Poppins:600);
/* navbar style  */
.get-a-quote {
    height: 39px;
    letter-spacing: 0;
    line-height: 32.4px;
    background-color: aliceblue;
    border-radius: 50px;
    box-shadow: 5px 2px 2px #00000040;
    text-align: center;
    padding: 5px 10px;
    width: 170px;
}

.get-a-quote a {
    text-decoration: none;
    color: black;
}

/* home page style  */
.product-background .background {
    position: relative;
}

@media (min-width: 310px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -140px;
        margin-left: 80px;
        z-index: 20;
    }

}

@media (min-width: 567px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -190px;
        margin-left: 150px;
    }
    
}

@media (min-width: 760px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 270px;
    }
    
}

@media (min-width: 1000px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 400px;
    }

}

@media (min-width: 1200px) {
    .product-background .title h1 {
        margin-top: -450px;
        position: absolute;
        margin-left: 600px;
    }

}

@media (min-width: 310px) {
    .service-background .title h1 {
        position: absolute;

        margin-top: -140px;
        margin-left: 80px;
        z-index: 20;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-100px;
    margin-left:0px;
    width: 100%;
    font-size: 10px;
  } */
}

@media (min-width: 567px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -190px;
        margin-left: 150px;
    }

}

@media (min-width: 760px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 270px;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-230px;
    margin-left:20px;
    width: 90%;
    font-size: 15px;
  } */
}

@media (min-width: 1000px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 400px;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-260px;
    margin-left:20px;
    width: 90%;
    font-size: 18px;
  } */
}

@media (min-width: 1200px) {
    .service-background .title h1 {
        margin-top: -450px;
        position: absolute;
        margin-left: 600px;
    }

    .service-background .background p {
        position: absolute;
        margin-top: -300px;
        padding-left: 330px;
        width: 90%;
        font-size: 18px;
    }
}

.naV {
    position: relative;
    z-index: 1;
}


.main-container1{
   
    min-height: 100vh;
    min-width: 100%;
    /* background-color: #777777; */
    background-color: white;
    overflow-y: hidden; 
    overflow-x: hidden; 
  }
  
  .product{
    padding-top: 100px;
  }

  .product .row { 
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    /* margin-left: 70px; */
    /* padding-top: -250px; */
    /* margin: auto; */
  }
  
  /* Create four equal columns that sits next to each other */
  .product .column {
     
    flex: 33% 1;
    max-width: 33%;
    padding: 0 10px;
    position: relative;
    top: -40px;
  }
  
  .product .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .product .column {
      flex: 100% 1;
      max-width: 100%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
   @media screen and (max-width: 600px) {
    .product .column {
      flex: 100% 1;
      max-width: 100%;
    }
  }
  
  .product .column img{
  cursor: pointer;
  }
  
  .product .column #zoom-out figure img{
  -webkit-transform: scale(.9);
          transform: scale(.9);
  transition: 0.3s ease-in-out; 
  }
  .product .column  #zoom-out figure:hover img{
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: aquamarine;
  }
  
.logo-color2 {
  color: #ead410 !important;
}

.bottommargin_40 {
  margin-bottom: 40px;
}

.margin_auto {
  margin: auto;
}

.with_border {
  border: 2px solid #e7eaeb;
}

.pointer {
  cursor: pointer;
}

.bold-text {
  font-weight: 500;
}

.post:hover .blog-img{
  -webkit-filter: blur(1.5px);
  filter: blur(1.5px);
  transition: linear 0.1s;
}

.single-post {
  margin-top: 60px;
  margin-bottom: 60px;
}

.rounded {
  border-radius: 5px;
}

.entry-title {
  margin-bottom: 1rem;
  color: var(--cloud-burst);
  font-family: var(--font-family-circular_std-bold);
  margin-top: 2.5rem;
}

.entry-title2 {
  margin-top: 1rem;
}

.entry-title a {
  color: #011932;
  text-decoration: none;
}

.entry-title a:hover {
  opacity: 0.7;
}

.bullet-points {
  font-size: 17px;
  margin-bottom: 0.8rem;
}

.content {
  color: var(--santas-gray);
  font-family: var(--font-family-roboto);
  font-weight: 300;
  line-height: 1.75rem;
}

.content-box {
  padding: 35px;
}

.link {
  text-decoration: none;
}

.link:hover {
  color: #ead410;
  opacity: 0.7;
  text-decoration: none;
}

.item-content {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .item-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .single-post {
    margin-left: 12%;
    margin-right: 12%;
  }
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
@font-face {
  font-family: "Visby Round CF-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/VisbyRoundCF-Regular") format("opentype");
}
@font-face {
  font-family: "Circular Std-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/CircularStd-Bold") format("opentype");
}
@font-face {
  font-family: "Bebas Neue Cyrillic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/BebasNeueCyrillic-Regular") format("truetype");
}
@font-face {
  font-family: "Graphik-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/Graphik-Bold") format("opentype");
}
@font-face {
  font-family: "Graphik-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Graphik-Regular") format("opentype");
}

.span0 {
  color: rgba(40, 158, 243, 1);
  color: var(--dodger-blue);
} 

  
  :root {
    --midnight: 
  rgba(1, 25, 50, 1);
    --eerie-black: 
  rgba(26, 26, 26, 1);
    --cloud-burst: 
  rgba(39, 45, 78, 1);
    --dodger-blue: 
  rgba(40, 158, 243, 1);
    --mine-shaft-2: 
  rgba(51, 51, 51, 0.502);
    --mine-shaft: 
  rgba(51, 51, 51, 1);
    --masala: 
  rgba(61, 61, 61, 1);
    --green: 
  rgba(75, 238, 111, 1);
    --amethyst: 
  rgba(100, 93, 142, 0.298);
    --inspiration: 
  rgba(100, 93, 142, 1);
    --dove-gray: 
  rgba(109, 109, 109, 1);
    --santas-gray: 
  rgba(148, 162, 179, 1);
    --cloud: 
  rgba(199, 199, 199, 1);
    --quill-gray: 
  rgba(214, 214, 214, 1);
    --buttercup: 
  rgba(234, 172, 13, 1);
    --lightning-yellow: 
  rgba(234, 189, 28, 1);
    --barberry: 
  rgba(234, 212, 16, 1);
    --golden-dream: 
  rgba(239, 221, 59, 1);
    --coconut: 
  rgba(252, 253, 255, 1);
    --white-2: 
  rgba(255, 255, 255, 0.298);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-s: 15px;
    --font-size-m: 18px;
    --font-size-l: 20px;
    --font-size-xl: 22px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 50px;
    --font-size-xxxxl: 64px;
  
    --font-family-bebas_neue: "Bebas Neue";
    --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
    --font-family-circular_std-bold: "Circular Std-Bold";
    --font-family-graphik-bold: "Graphik-Bold";
    --font-family-graphik-regular: "Graphik-Regular";
    --font-family-poppins: "Poppins";
    --font-family-raleway: "Raleway";
    --font-family-roboto: "Roboto";
    --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
  }
  
  
  .graphik-regular-normal-amethyst-15px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Regular";
    font-family: var(--font-family-graphik-regular);
    font-size: 24px;
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: rgba(40, 158, 243, 1);
    color: var(--dodger-blue);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 32px;
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  

  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 64px;
    font-size: var(--font-size-xxxxl);
    font-weight: 200;
    font-style: normal;
  }
  
  .raleway-bold-lightning-yellow-36px {
    color: rgba(234, 189, 28, 1);
    color: var(--lightning-yellow);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-golden-dream-18px {
    color: rgba(239, 221, 59, 1);
    color: var(--golden-dream);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: rgba(252, 253, 255, 1);
    color: var(--coconut);
    font-family: "Poppins";
    font-family: var(--font-family-poppins);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
/*   */
  .visbyroundcf-regular-normal-cloud-18px {
    color: rgba(199, 199, 199, 1);
    color: var(--cloud);
    font-family: "Visby Round CF-Regular";
    font-family: var(--font-family-visby_round_cf-regular);
    font-size: 110%;
    font-weight: 400;
    font-style: normal;
  }
  
  .raleway-bold-mine-shaft-36px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-mine-shaft-30px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-normal-santas-gray-18px {
    color: rgba(148, 162, 179, 1);
    color: var(--santas-gray);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 100%;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto--20r {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 20px;
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: rgba(214, 214, 214, 1);
    color: var(--quill-gray);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Bold";
    font-family: var(--font-family-graphik-bold);
    font-size: 20px;
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: rgba(234, 212, 16, 1);
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(100, 93, 142, 0.298);
    border-color: var(--amethyst);
  }
  
  .raleway-bold-eerie-black-18px {
    color: rgba(26, 26, 26, 1);
    color: var(--eerie-black);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .overlap-group-1 {
    align-items: flex-end;
    background-color: rgba(1, 25, 50, 1);
    background-color: var(--midnight);
    border-radius: 50px;
    display: flex;
    height: 55px;
    margin-left: 2px;
    margin-top: 67px;
    width: 276px;
    padding: 3px 77px;
  }
  
  
  
  .text-4 {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 50px;
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    letter-spacing: 0;
    min-height: 118px;
  }
  .text-5 {
    color: rgba(51, 51, 51, 0.502);
    color: var(--mine-shaft-2);
    line-height: 34.9px;
    margin-left: 2px;
    margin-top: 7px;
    min-height: 177px;
    /* width: 553px; */
  }
  
  :root {
    --black: 
  rgba(0, 0, 0, 0.588);
    --midnight: 
  rgba(1, 25, 50, 1);
    --black-pearl: 
  rgba(9, 19, 46, 0.298);
    --eerie-black: 
  rgba(26, 26, 26, 1);
    --cloud-burst: 
  rgba(39, 45, 78, 1);
    --dodger-blue: 
  rgba(40, 158, 243, 1);
    --mine-shaft-2: 
  rgba(51, 51, 51, 0.502);
    --mine-shaft: 
  rgba(51, 51, 51, 1);
    --masala: 
  rgba(61, 61, 61, 1);
    --masala: 
  rgba(63, 59, 59, 1);
    --green: 
  rgba(75, 238, 111, 1);
    --amethyst: 
  rgba(100, 93, 142, 0.298);
    --inspiration: 
  rgba(100, 93, 142, 1);
    --dove-gray: 
  rgba(109, 109, 109, 1);
    --santas-gray: 
  rgba(148, 162, 179, 1);
    --cloud: 
  rgba(199, 199, 199, 1);
    --quill-gray: 
  rgba(214, 214, 214, 1);
    --buttercup: 
  rgba(234, 172, 13, 1);
    --lightning-yellow: 
  rgba(234, 189, 28, 1);
    --barberry: 
  rgba(234, 212, 16, 1);
    --golden-dream: 
  rgba(239, 221, 59, 1);
    --coconut: 
  rgba(252, 253, 255, 1);
    --white-2: 
  rgba(255, 255, 255, 0.298);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-s: 15px;
    --font-size-m: 18px;
    --font-size-l: 20px;
    --font-size-xl: 22px;
    --font-size-xl: 32px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 50px;
    --font-size-xxxxl: 64px;
  
    --font-family-bebas_neue: "Bebas Neue";
    --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
    --font-family-circular_std-bold: "Circular Std-Bold";
    --font-family-graphik-bold: "Graphik-Bold";
    --font-family-graphik-regular: "Graphik-Regular";
    --font-family-montserrat: "Montserrat";
    --font-family-poppins: "Poppins";
    --font-family-raleway: "Raleway";
    --font-family-roboto: "Roboto";
    --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
  }
  
  
  .graphik-bold-white-20px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Graphik-Bold";
    font-family: var(--font-family-graphik-bold);
    font-size: 20px;
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .graphik-regular-normal-amethyst-15px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Regular";
    font-family: var(--font-family-graphik-regular);
    font-size: 24px;
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: rgba(40, 158, 243, 1);
    color: var(--dodger-blue);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 32px;
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  
  .circularstd-bold-cloud-burst-36px {
    color: rgba(39, 45, 78, 1);
    color: var(--cloud-burst);
    font-family: "Circular Std-Bold";
    font-family: var(--font-family-circular_std-bold);
    font-size: 200%;
    font-weight: 700;
    font-style: normal;
  }
  
  .border-1px-black-pearl {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(9, 19, 46, 0.298);
    border-color: var(--black-pearl);
  }
  
  .graphik-regular-normal-white-15px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Graphik-Regular";
    font-family: var(--font-family-graphik-regular);
    font-size: 24px;
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 64px;
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  
  
  .raleway-bold-golden-dream-18px {
    color: rgba(239, 221, 59, 1);
    color: var(--golden-dream);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: rgba(252, 253, 255, 1);
    color: var(--coconut);
    font-family: "Poppins";
    font-family: var(--font-family-poppins);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
  
 
 
  .raleway-bold-mine-shaft-36px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
 
  
  .raleway-bold-mine-shaft-30px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  
  .roboto--20r {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 20px;
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: rgba(214, 214, 214, 1);
    color: var(--quill-gray);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Bold";
    font-family: var(--font-family-graphik-bold);
    font-size: 20px;
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: rgba(234, 212, 16, 1);
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(100, 93, 142, 0.298);
    border-color: var(--amethyst);
  }
  
  .raleway-bold-white-50px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 50px;
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-eerie-black-18px {
    color: rgba(26, 26, 26, 1);
    color: var(--eerie-black);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  
  
  
  .graphik-bold-white-20px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Graphik-Bold";
    font-family: var(--font-family-graphik-bold);
    font-size: 20px;
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .graphik-regular-normal-amethyst-15px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Regular";
    font-family: var(--font-family-graphik-regular);
    font-size: 24px;
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: rgba(40, 158, 243, 1);
    color: var(--dodger-blue);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 32px;
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  

  .border-1px-black-pearl {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(9, 19, 46, 0.298);
    border-color: var(--black-pearl);
  }
  
  .graphik-regular-normal-white-15px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Graphik-Regular";
    font-family: var(--font-family-graphik-regular);
    font-size: 24px;
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 64px;
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  
  
  .raleway-bold-golden-dream-18px {
    color: rgba(239, 221, 59, 1);
    color: var(--golden-dream);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: rgba(252, 253, 255, 1);
    color: var(--coconut);
    font-family: "Poppins";
    font-family: var(--font-family-poppins);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-midnight-64px {
    color: rgba(1, 25, 50, 1);
    color: var(--midnight);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 360%;
    font-weight: 400;
    font-style: normal;
  }
  
 
  .raleway-bold-mine-shaft-36px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 36px;
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
 
  .raleway-bold-mine-shaft-30px {
    color: rgba(51, 51, 51, 1);
    color: var(--mine-shaft);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto--20r {
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 20px;
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: rgba(214, 214, 214, 1);
    color: var(--quill-gray);
    font-family: "Bebas Neue Cyrillic-Regular";
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: rgba(100, 93, 142, 1);
    color: var(--inspiration);
    font-family: "Graphik-Bold";
    font-family: var(--font-family-graphik-bold);
    font-size: 20px;
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: rgba(234, 212, 16, 1);
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Roboto";
    font-family: var(--font-family-roboto);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(100, 93, 142, 0.298);
    border-color: var(--amethyst);
  }
  
  .raleway-bold-white-50px {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 50px;
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-eerie-black-18px {
    color: rgba(26, 26, 26, 1);
    color: var(--eerie-black);
    font-family: "Raleway";
    font-family: var(--font-family-raleway);
    font-size: 27.1px;
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 
  

:root {
  --black: 
rgba(0, 0, 0, 0.588);
  --midnight: 
rgba(1, 25, 50, 1);
  --black-pearl: 
rgba(9, 19, 46, 0.298);
  --eerie-black: 
rgba(26, 26, 26, 1);
  --cloud-burst: 
rgba(39, 45, 78, 1);
  --dodger-blue: 
rgba(40, 158, 243, 1);
  --mine-shaft-2: 
rgba(51, 51, 51, 0.502);
  --mine-shaft: 
rgba(51, 51, 51, 1);
  --masala: 
rgba(61, 61, 61, 1);
  --masala: 
rgba(63, 59, 59, 1);
  --green: 
rgba(75, 238, 111, 1);
  --emperor: 
rgba(77, 72, 72, 1);
  --amethyst: 
rgba(100, 93, 142, 0.298);
  --inspiration: 
rgba(100, 93, 142, 1);
  --dove-gray: 
rgba(109, 109, 109, 1);
  --spicy-pink: 
rgba(121, 113, 113, 1);
  --santas-gray: 
rgba(148, 162, 179, 1);
  --cloud: 
rgba(199, 199, 199, 1);
  --quill-gray: 
rgba(214, 214, 214, 1);
  --buttercup: 
rgba(234, 172, 13, 1);
  --lightning-yellow: 
rgba(234, 189, 28, 1);
  --barberry: 
rgba(234, 212, 16, 1);
  --golden-dream: 
rgba(239, 221, 59, 1);
  --concrete: 
rgba(243, 243, 243, 1);
  --coconut: 
rgba(252, 253, 255, 1);
  --white-2: 
rgba(255, 255, 255, 0.298);
  --white: 
rgba(255, 255, 255, 1);

  --font-size-s: 24px;
  --font-size-m: 27.1px;
  --font-size-xl: 22px;
  --font-size-xl: 32px;
  --font-size-xxl: 36px;
  --font-size-xxxl: 50px;
  --font-size-xxxxl: 64px;
  --font-size-s2: 15px;
  --font-size-m2: 18px;
  --font-size-l2: 20px;

  --font-family-bebas_neue: "Bebas Neue";
  --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
  --font-family-circular_std-bold: "Circular Std-Bold";
  --font-family-graphik-bold: "Graphik-Bold";
  --font-family-graphik-regular: "Graphik-Regular";
  --font-family-montserrat: "Montserrat";
  --font-family-poppins: "Poppins";
  --font-family-raleway: "Raleway";
  --font-family-roboto: "Roboto";
  --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
}


.graphik-bold-white-20px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Graphik-Bold";
  font-family: var(--font-family-graphik-bold);
  font-size: 20px;
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
}

.graphik-regular-normal-amethyst-15px {
  color: rgba(100, 93, 142, 1);
  color: var(--inspiration);
  font-family: "Graphik-Regular";
  font-family: var(--font-family-graphik-regular);
  font-size: 15px;
  font-size: var(--font-size-s2);
  font-weight: 400;
  font-style: normal;
}

.roboto--18b {
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 18px;
  font-size: var(--font-size-m2);
  letter-spacing: 0.00px;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-dodger-blue-22px {
  color: rgba(40, 158, 243, 1);
  color: var(--dodger-blue);
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 32px;
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
}

.border-1px-black-pearl {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(9, 19, 46, 0.298);
  border-color: var(--black-pearl);
}

.graphik-regular-normal-white-15px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Graphik-Regular";
  font-family: var(--font-family-graphik-regular);
  font-size: 15px;
  font-size: var(--font-size-s2);
  font-weight: 400;
  font-style: normal;
}

.bebasneuecyrillic-regular-normal-mine-shaft-64px {
  color: rgba(51, 51, 51, 1);
  color: var(--mine-shaft);
  font-family: "Bebas Neue Cyrillic-Regular";
  font-family: var(--font-family-bebas_neue_cyrillic-regular);
  font-size: 64px;
  font-size: var(--font-size-xxxxl);
  font-weight: 400;
  font-style: normal;
}

.raleway-bold-lightning-yellow-36px {
  color: rgba(234, 189, 28, 1);
  color: var(--lightning-yellow);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 150%;
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-golden-dream-18px {
  color: rgba(239, 221, 59, 1);
  color: var(--golden-dream);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 18px;
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
}

.poppins-semi-bold-coconut-36px {
  color: rgba(252, 253, 255, 1);
  color: var(--coconut);
  font-family: "Poppins";
  font-family: var(--font-family-poppins);
  font-size: 36px;
  font-size: var(--font-size-xxl);
  font-weight: 600;
  font-style: normal;
}


.roboto-normal-white-36px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 36px;
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}

.raleway-bold-mine-shaft-36px {
  color: rgba(51, 51, 51, 1);
  color: var(--mine-shaft);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 36px;
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-spicy-pink-27-1px {
  color: rgba(121, 113, 113, 1);
  color: var(--spicy-pink);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 130%;
  font-weight: 100;
  font-style: normal;
}

.raleway-bold-mine-shaft-50px {
  color: rgba(51, 51, 51, 1);
  color: var(--mine-shaft);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 250%;
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-mine-shaft-30px {
  color: rgba(51, 51, 51, 1);
  color: var(--mine-shaft);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 30;
  font-weight: 700;
  font-style: normal;
}

.roboto-medium-white-18px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 18px;
  font-size: var(--font-size-m2);
  font-weight: 500;
  font-style: normal;
}


.roboto--20r {
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 20px;
  font-size: var(--font-size-l2);
  letter-spacing: 0.00px;
  font-weight: 400;
  font-style: normal;
}

.bebasneuecyrillic-regular-normal-quill-gray-22-4px {
  color: rgba(214, 214, 214, 1);
  color: var(--quill-gray);
  font-family: "Bebas Neue Cyrillic-Regular";
  font-family: var(--font-family-bebas_neue_cyrillic-regular);
  font-size: 22.40000343322754;
  font-weight: 400;
  font-style: normal;
}

.graphik-bold-amethyst-20px {
  color: rgba(100, 93, 142, 1);
  color: var(--inspiration);
  font-family: "Graphik-Bold";
  font-family: var(--font-family-graphik-bold);
  font-size: 20px;
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
}

.border-5px-barberry {
  border-width: 5px;
  border-style: solid;
  border-color: rgba(234, 212, 16, 1);
  border-color: var(--barberry);
}

.roboto-normal-white-18px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 18px;
  font-size: var(--font-size-m2);
  font-weight: 400;
  font-style: normal;
}

.border-1px-amethyst {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(100, 93, 142, 0.298);
  border-color: var(--amethyst);
}

.raleway-bold-white-50px {
  color: rgba(255, 255, 255, 1);
  color: var(--white);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 50px;
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-eerie-black-18px {
  color: rgba(26, 26, 26, 1);
  color: var(--eerie-black);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 18px;
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-emperor-24px {
  color: rgba(77, 72, 72, 1);
  color: var(--emperor);
  font-family: "Raleway";
  font-family: var(--font-family-raleway);
  font-size: 120%;
  font-weight: 700;
  font-style: normal;
}

.roboto-normal-masala-18px {
  color: rgba(63, 59, 59, 1);
  color: var(--masala);
  font-family: "Roboto";
  font-family: var(--font-family-roboto);
  font-size: 100%;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6rem;
}
