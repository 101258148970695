/* navbar style  */
.get-a-quote {
    height: 39px;
    letter-spacing: 0;
    line-height: 32.4px;
    background-color: aliceblue;
    border-radius: 50px;
    box-shadow: 5px 2px 2px #00000040;
    text-align: center;
    padding: 5px 10px;
    width: 170px;
}

.get-a-quote a {
    text-decoration: none;
    color: black;
}

/* home page style  */
.product-background .background {
    position: relative;
}

@media (min-width: 310px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -140px;
        margin-left: 80px;
        z-index: 20;
    }

}

@media (min-width: 567px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -190px;
        margin-left: 150px;
    }
    
}

@media (min-width: 760px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 270px;
    }
    
}

@media (min-width: 1000px) {
    .product-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 400px;
    }

}

@media (min-width: 1200px) {
    .product-background .title h1 {
        margin-top: -450px;
        position: absolute;
        margin-left: 600px;
    }

}

@media (min-width: 310px) {
    .service-background .title h1 {
        position: absolute;

        margin-top: -140px;
        margin-left: 80px;
        z-index: 20;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-100px;
    margin-left:0px;
    width: 100%;
    font-size: 10px;
  } */
}

@media (min-width: 567px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -190px;
        margin-left: 150px;
    }

}

@media (min-width: 760px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 270px;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-230px;
    margin-left:20px;
    width: 90%;
    font-size: 15px;
  } */
}

@media (min-width: 1000px) {
    .service-background .title h1 {
        position: absolute;
        margin-top: -250px;
        margin-left: 400px;
    }
    /* .service-background .background p{
    position: absolute;
    margin-top:-260px;
    margin-left:20px;
    width: 90%;
    font-size: 18px;
  } */
}

@media (min-width: 1200px) {
    .service-background .title h1 {
        margin-top: -450px;
        position: absolute;
        margin-left: 600px;
    }

    .service-background .background p {
        position: absolute;
        margin-top: -300px;
        padding-left: 330px;
        width: 90%;
        font-size: 18px;
    }
}

.naV {
    position: relative;
    z-index: 1;
}

