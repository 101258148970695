body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500|Raleway:700,600|Bebas+Neue:400|Poppins:600");
@font-face {
  font-family: "Visby Round CF-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/VisbyRoundCF-Regular") format("opentype");
}
@font-face {
  font-family: "Circular Std-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/CircularStd-Bold") format("opentype");
}
@font-face {
  font-family: "Bebas Neue Cyrillic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/BebasNeueCyrillic-Regular") format("truetype");
}
@font-face {
  font-family: "Graphik-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/Graphik-Bold") format("opentype");
}
@font-face {
  font-family: "Graphik-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Graphik-Regular") format("opentype");
}

.span0 {
  color: var(--dodger-blue);
} 

  
  :root {
    --midnight: 
  rgba(1, 25, 50, 1);
    --eerie-black: 
  rgba(26, 26, 26, 1);
    --cloud-burst: 
  rgba(39, 45, 78, 1);
    --dodger-blue: 
  rgba(40, 158, 243, 1);
    --mine-shaft-2: 
  rgba(51, 51, 51, 0.502);
    --mine-shaft: 
  rgba(51, 51, 51, 1);
    --masala: 
  rgba(61, 61, 61, 1);
    --green: 
  rgba(75, 238, 111, 1);
    --amethyst: 
  rgba(100, 93, 142, 0.298);
    --inspiration: 
  rgba(100, 93, 142, 1);
    --dove-gray: 
  rgba(109, 109, 109, 1);
    --santas-gray: 
  rgba(148, 162, 179, 1);
    --cloud: 
  rgba(199, 199, 199, 1);
    --quill-gray: 
  rgba(214, 214, 214, 1);
    --buttercup: 
  rgba(234, 172, 13, 1);
    --lightning-yellow: 
  rgba(234, 189, 28, 1);
    --barberry: 
  rgba(234, 212, 16, 1);
    --golden-dream: 
  rgba(239, 221, 59, 1);
    --coconut: 
  rgba(252, 253, 255, 1);
    --white-2: 
  rgba(255, 255, 255, 0.298);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-s: 15px;
    --font-size-m: 18px;
    --font-size-l: 20px;
    --font-size-xl: 22px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 50px;
    --font-size-xxxxl: 64px;
  
    --font-family-bebas_neue: "Bebas Neue";
    --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
    --font-family-circular_std-bold: "Circular Std-Bold";
    --font-family-graphik-bold: "Graphik-Bold";
    --font-family-graphik-regular: "Graphik-Regular";
    --font-family-poppins: "Poppins";
    --font-family-raleway: "Raleway";
    --font-family-roboto: "Roboto";
    --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
  }
  
  
  .graphik-regular-normal-amethyst-15px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: var(--dodger-blue);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  

  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: var(--mine-shaft);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: var(--font-size-xxxxl);
    font-weight: 200;
    font-style: normal;
  }
  
  .raleway-bold-lightning-yellow-36px {
    color: var(--lightning-yellow);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-golden-dream-18px {
    color: var(--golden-dream);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: var(--coconut);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
/*   */
  .visbyroundcf-regular-normal-cloud-18px {
    color: var(--cloud);
    font-family: var(--font-family-visby_round_cf-regular);
    font-size: 110%;
    font-weight: 400;
    font-style: normal;
  }
  
  .raleway-bold-mine-shaft-36px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-mine-shaft-30px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-normal-santas-gray-18px {
    color: var(--santas-gray);
    font-family: var(--font-family-roboto);
    font-size: 100%;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto--20r {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: var(--quill-gray);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: var(--amethyst);
  }
  
  .raleway-bold-eerie-black-18px {
    color: var(--eerie-black);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  
  @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500|Raleway:700,600|Bebas+Neue:400|Poppins:600");
  
  .overlap-group-1 {
    align-items: flex-end;
    background-color: var(--midnight);
    border-radius: 50px;
    display: flex;
    height: 55px;
    margin-left: 2px;
    margin-top: 67px;
    width: 276px;
    padding: 3px 77px;
  }
  
  
  
  .text-4 {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    letter-spacing: 0;
    min-height: 118px;
  }
  .text-5 {
    color: var(--mine-shaft-2);
    line-height: 34.9px;
    margin-left: 2px;
    margin-top: 7px;
    min-height: 177px;
    /* width: 553px; */
  }
  
  :root {
    --black: 
  rgba(0, 0, 0, 0.588);
    --midnight: 
  rgba(1, 25, 50, 1);
    --black-pearl: 
  rgba(9, 19, 46, 0.298);
    --eerie-black: 
  rgba(26, 26, 26, 1);
    --cloud-burst: 
  rgba(39, 45, 78, 1);
    --dodger-blue: 
  rgba(40, 158, 243, 1);
    --mine-shaft-2: 
  rgba(51, 51, 51, 0.502);
    --mine-shaft: 
  rgba(51, 51, 51, 1);
    --masala: 
  rgba(61, 61, 61, 1);
    --masala: 
  rgba(63, 59, 59, 1);
    --green: 
  rgba(75, 238, 111, 1);
    --amethyst: 
  rgba(100, 93, 142, 0.298);
    --inspiration: 
  rgba(100, 93, 142, 1);
    --dove-gray: 
  rgba(109, 109, 109, 1);
    --santas-gray: 
  rgba(148, 162, 179, 1);
    --cloud: 
  rgba(199, 199, 199, 1);
    --quill-gray: 
  rgba(214, 214, 214, 1);
    --buttercup: 
  rgba(234, 172, 13, 1);
    --lightning-yellow: 
  rgba(234, 189, 28, 1);
    --barberry: 
  rgba(234, 212, 16, 1);
    --golden-dream: 
  rgba(239, 221, 59, 1);
    --coconut: 
  rgba(252, 253, 255, 1);
    --white-2: 
  rgba(255, 255, 255, 0.298);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-s: 15px;
    --font-size-m: 18px;
    --font-size-l: 20px;
    --font-size-xl: 22px;
    --font-size-xl: 32px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 50px;
    --font-size-xxxxl: 64px;
  
    --font-family-bebas_neue: "Bebas Neue";
    --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
    --font-family-circular_std-bold: "Circular Std-Bold";
    --font-family-graphik-bold: "Graphik-Bold";
    --font-family-graphik-regular: "Graphik-Regular";
    --font-family-montserrat: "Montserrat";
    --font-family-poppins: "Poppins";
    --font-family-raleway: "Raleway";
    --font-family-roboto: "Roboto";
    --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
  }
  
  
  .graphik-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-graphik-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .graphik-regular-normal-amethyst-15px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: var(--dodger-blue);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  
  .circularstd-bold-cloud-burst-36px {
    color: var(--cloud-burst);
    font-family: var(--font-family-circular_std-bold);
    font-size: 200%;
    font-weight: 700;
    font-style: normal;
  }
  
  .border-1px-black-pearl {
    border-width: 1px;
    border-style: solid;
    border-color: var(--black-pearl);
  }
  
  .graphik-regular-normal-white-15px {
    color: var(--white);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: var(--mine-shaft);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  
  
  .raleway-bold-golden-dream-18px {
    color: var(--golden-dream);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: var(--coconut);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
  
 
 
  .raleway-bold-mine-shaft-36px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
 
  
  .raleway-bold-mine-shaft-30px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  
  .roboto--20r {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: var(--quill-gray);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: var(--amethyst);
  }
  
  .raleway-bold-white-50px {
    color: var(--white);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-eerie-black-18px {
    color: var(--eerie-black);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  
  
  
  .graphik-bold-white-20px {
    color: var(--white);
    font-family: var(--font-family-graphik-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .graphik-regular-normal-amethyst-15px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto--18b {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    letter-spacing: 0.00px;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-dodger-blue-22px {
    color: var(--dodger-blue);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
  }
  

  .border-1px-black-pearl {
    border-width: 1px;
    border-style: solid;
    border-color: var(--black-pearl);
  }
  
  .graphik-regular-normal-white-15px {
    color: var(--white);
    font-family: var(--font-family-graphik-regular);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-mine-shaft-64px {
    color: var(--mine-shaft);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  
  
  .raleway-bold-golden-dream-18px {
    color: var(--golden-dream);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-semi-bold-coconut-36px {
    color: var(--coconut);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxl);
    font-weight: 600;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-midnight-64px {
    color: var(--midnight);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 360%;
    font-weight: 400;
    font-style: normal;
  }
  
 
  .raleway-bold-mine-shaft-36px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    font-style: normal;
  }
  
 
  .raleway-bold-mine-shaft-30px {
    color: var(--mine-shaft);
    font-family: var(--font-family-raleway);
    font-size: 30;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-medium-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto--20r {
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    letter-spacing: 0.00px;
    font-weight: 400;
    font-style: normal;
  }
  
  .bebasneuecyrillic-regular-normal-quill-gray-22-4px {
    color: var(--quill-gray);
    font-family: var(--font-family-bebas_neue_cyrillic-regular);
    font-size: 22.40000343322754;
    font-weight: 400;
    font-style: normal;
  }
  
  .graphik-bold-amethyst-20px {
    color: var(--inspiration);
    font-family: var(--font-family-graphik-bold);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  
  .border-5px-barberry {
    border-width: 5px;
    border-style: solid;
    border-color: var(--barberry);
  }
  
  .roboto-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  
  .border-1px-amethyst {
    border-width: 1px;
    border-style: solid;
    border-color: var(--amethyst);
  }
  
  .raleway-bold-white-50px {
    color: var(--white);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    font-style: normal;
  }
  
  .raleway-bold-eerie-black-18px {
    color: var(--eerie-black);
    font-family: var(--font-family-raleway);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
  }
 
  

:root {
  --black: 
rgba(0, 0, 0, 0.588);
  --midnight: 
rgba(1, 25, 50, 1);
  --black-pearl: 
rgba(9, 19, 46, 0.298);
  --eerie-black: 
rgba(26, 26, 26, 1);
  --cloud-burst: 
rgba(39, 45, 78, 1);
  --dodger-blue: 
rgba(40, 158, 243, 1);
  --mine-shaft-2: 
rgba(51, 51, 51, 0.502);
  --mine-shaft: 
rgba(51, 51, 51, 1);
  --masala: 
rgba(61, 61, 61, 1);
  --masala: 
rgba(63, 59, 59, 1);
  --green: 
rgba(75, 238, 111, 1);
  --emperor: 
rgba(77, 72, 72, 1);
  --amethyst: 
rgba(100, 93, 142, 0.298);
  --inspiration: 
rgba(100, 93, 142, 1);
  --dove-gray: 
rgba(109, 109, 109, 1);
  --spicy-pink: 
rgba(121, 113, 113, 1);
  --santas-gray: 
rgba(148, 162, 179, 1);
  --cloud: 
rgba(199, 199, 199, 1);
  --quill-gray: 
rgba(214, 214, 214, 1);
  --buttercup: 
rgba(234, 172, 13, 1);
  --lightning-yellow: 
rgba(234, 189, 28, 1);
  --barberry: 
rgba(234, 212, 16, 1);
  --golden-dream: 
rgba(239, 221, 59, 1);
  --concrete: 
rgba(243, 243, 243, 1);
  --coconut: 
rgba(252, 253, 255, 1);
  --white-2: 
rgba(255, 255, 255, 0.298);
  --white: 
rgba(255, 255, 255, 1);

  --font-size-s: 24px;
  --font-size-m: 27.1px;
  --font-size-xl: 22px;
  --font-size-xl: 32px;
  --font-size-xxl: 36px;
  --font-size-xxxl: 50px;
  --font-size-xxxxl: 64px;
  --font-size-s2: 15px;
  --font-size-m2: 18px;
  --font-size-l2: 20px;

  --font-family-bebas_neue: "Bebas Neue";
  --font-family-bebas_neue_cyrillic-regular: "Bebas Neue Cyrillic-Regular";
  --font-family-circular_std-bold: "Circular Std-Bold";
  --font-family-graphik-bold: "Graphik-Bold";
  --font-family-graphik-regular: "Graphik-Regular";
  --font-family-montserrat: "Montserrat";
  --font-family-poppins: "Poppins";
  --font-family-raleway: "Raleway";
  --font-family-roboto: "Roboto";
  --font-family-visby_round_cf-regular: "Visby Round CF-Regular";
}


.graphik-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-graphik-bold);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
}

.graphik-regular-normal-amethyst-15px {
  color: var(--inspiration);
  font-family: var(--font-family-graphik-regular);
  font-size: var(--font-size-s2);
  font-weight: 400;
  font-style: normal;
}

.roboto--18b {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m2);
  letter-spacing: 0.00px;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-dodger-blue-22px {
  color: var(--dodger-blue);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
}

.border-1px-black-pearl {
  border-width: 1px;
  border-style: solid;
  border-color: var(--black-pearl);
}

.graphik-regular-normal-white-15px {
  color: var(--white);
  font-family: var(--font-family-graphik-regular);
  font-size: var(--font-size-s2);
  font-weight: 400;
  font-style: normal;
}

.bebasneuecyrillic-regular-normal-mine-shaft-64px {
  color: var(--mine-shaft);
  font-family: var(--font-family-bebas_neue_cyrillic-regular);
  font-size: var(--font-size-xxxxl);
  font-weight: 400;
  font-style: normal;
}

.raleway-bold-lightning-yellow-36px {
  color: var(--lightning-yellow);
  font-family: var(--font-family-raleway);
  font-size: 150%;
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-golden-dream-18px {
  color: var(--golden-dream);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
}

.poppins-semi-bold-coconut-36px {
  color: var(--coconut);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-weight: 600;
  font-style: normal;
}


.roboto-normal-white-36px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}

.raleway-bold-mine-shaft-36px {
  color: var(--mine-shaft);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-spicy-pink-27-1px {
  color: var(--spicy-pink);
  font-family: var(--font-family-raleway);
  font-size: 130%;
  font-weight: 100;
  font-style: normal;
}

.raleway-bold-mine-shaft-50px {
  color: var(--mine-shaft);
  font-family: var(--font-family-raleway);
  font-size: 250%;
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-mine-shaft-30px {
  color: var(--mine-shaft);
  font-family: var(--font-family-raleway);
  font-size: 30;
  font-weight: 700;
  font-style: normal;
}

.roboto-medium-white-18px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m2);
  font-weight: 500;
  font-style: normal;
}


.roboto--20r {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l2);
  letter-spacing: 0.00px;
  font-weight: 400;
  font-style: normal;
}

.bebasneuecyrillic-regular-normal-quill-gray-22-4px {
  color: var(--quill-gray);
  font-family: var(--font-family-bebas_neue_cyrillic-regular);
  font-size: 22.40000343322754;
  font-weight: 400;
  font-style: normal;
}

.graphik-bold-amethyst-20px {
  color: var(--inspiration);
  font-family: var(--font-family-graphik-bold);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
}

.border-5px-barberry {
  border-width: 5px;
  border-style: solid;
  border-color: var(--barberry);
}

.roboto-normal-white-18px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m2);
  font-weight: 400;
  font-style: normal;
}

.border-1px-amethyst {
  border-width: 1px;
  border-style: solid;
  border-color: var(--amethyst);
}

.raleway-bold-white-50px {
  color: var(--white);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-eerie-black-18px {
  color: var(--eerie-black);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-emperor-24px {
  color: var(--emperor);
  font-family: var(--font-family-raleway);
  font-size: 120%;
  font-weight: 700;
  font-style: normal;
}

.roboto-normal-masala-18px {
  color: var(--masala);
  font-family: var(--font-family-roboto);
  font-size: 100%;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6rem;
}